import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, Navigation, EffectFade } from "swiper";
import { Col, Container, Row } from "react-bootstrap";

import bgPasses from "@/images/bg/passes/top.jpg";

// Teams
import cracovia from "@/images/teams/cracovia.png";
import gornik from "@/images/teams/gornik.png";
import korona from "@/images/teams/korona.png";
import lech from "@/images/teams/lech.png";
import lechia from "@/images/teams/lechia.png";
import legia from "@/images/teams/legia.png";
import miedz from "@/images/teams/miedz.png";
import piast from "@/images/teams/piast.png";
import pogon from "@/images/teams/pogon.png";
import pucharPolski from "@/images/teams/puchar-polski.png";
import radomiak from "@/images/teams/radomiak.png";
import rakow from "@/images/teams/rakow.png";
import slask from "@/images/teams/slask.png";
import stal from "@/images/teams/stal.png";
import warta from "@/images/teams/warta.png";
import widzew from "@/images/teams/widzew.png";
import wisla from "@/images/teams/wisla.png";
import zaglebie from "@/images/teams/zaglebie.png";

SwiperCore.use([Autoplay, Navigation, EffectFade]);
const SliderTop = () => {
  const mainSlideOptions = {
    slidesPerView: 1,
    loop: false,
    effect: "fade",
  };
  return (
    <section className="main-slider slider-subpages">
      <Swiper {...mainSlideOptions}>
        <SwiperSlide>
          <div
            className="image-layer"
            style={{ backgroundImage: `url('${bgPasses}')` }}
          ></div>
          <Container className="w-auto animated fadeInUp pt-0">
            <Row className="passes-slider-heading">
              <Col lg={12} className="text-center">
                <h3 className="main-slider__title mb-3">KARNETY</h3>
                <h4 className="text-white">SEZON 2022/23</h4>
              </Col>
            </Row>
            <Row className="mt-5">
              <Col lg={12} className="text-center">
                <p className="main-slider__subtext">KARNET RUNDA JESIENNA</p>
              </Col>
              <div className="text-center passes-round-column mt-4 mx-auto">
                <img src={piast} />
                <img src={widzew} />
                <img src={radomiak} />
                <img src={miedz} />
                <img src={stal} />
                <img src={korona} />
                <img src={wisla} />
                <img src={legia} />
                <img src={lech} />
                <img src={pucharPolski} />
              </div>
            </Row>
            <Row className="mt-5">
              <Col lg={12} className="text-center">
                <p className="main-slider__subtext">KARNET SEZON</p>
              </Col>
              <div className="text-center passes-season-column mt-4 mx-auto">
                <img src={piast} />
                <img src={widzew} />
                <img src={radomiak} />
                <img src={miedz} />
                <img src={stal} />
                <img src={korona} />
                <img src={wisla} />
                <img src={legia} />
                <img src={lech} />
                <img src={pogon} />
                <img src={rakow} />
                <img src={gornik} />
                <img src={zaglebie} />
                <img src={lechia} />
                <img src={warta} />
                <img src={widzew} />
                <img src={cracovia} />
                <img src={pucharPolski} />
              </div>
            </Row>
          </Container>
        </SwiperSlide>
      </Swiper>
    </section>
  );
};

export default SliderTop;
