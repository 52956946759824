import React, { useState, useEffect } from "react";
import { Col, Container, Row, Nav, Table } from "react-bootstrap";
import Tab from "react-bootstrap/Tab";
import api from "@/axios";

import TabApiContent from "@/components/passes/tab-api-content";

import pricingImg from "@/images/icons/pricing.png";
import informatorImg from "@/images/icons/informator.png";
import pointsImg from "@/images/icons/points.png";
import stadiumImg from "@/images/icons/stadium.png";
import hospitalityImg from "@/images/icons/hospitality.png";
import termsImg from "@/images/icons/terms.png";

const TabArticles = () => {
  const [apiDataPricing, setDataPricing] = useState(null);
  const [apiDataInformator, setApiDataInformator] = useState(null);
  const [apiDataPunkty, setApiDataPunkty] = useState(null);
  const [apiDataStadion, setApiDataStadion] = useState(null);
  const [apiDataHospitality, setApiDataHospitality] = useState(null);
  const [apiDataRegulaminy, setApiDataRegulaminy] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  useEffect(() => {
    const getData = async () => {
      try {
        const responsePricing = await api.get(`/ticketprices/get`);
        let resDataPricing = responsePricing.data.filter(v => v.type === 0);
        setDataPricing(resDataPricing);

        const responseInformator = await api.get(
          `/pages/o/get/informatorkibicakarnety`
        );
        setApiDataInformator(responseInformator.data);

        const responsePunkty = await api.get(
          `/pages/o/get/punktysprzedazykarnety`
        );
        setApiDataPunkty(responsePunkty.data);

        const responseStadion = await api.get(`/pages/o/get/stadionkarnety`);
        setApiDataStadion(responseStadion.data);

        const responseHospitality = await api.get(
          `/pages/o/get/hospitalitykarnety`
        );
        setApiDataHospitality(responseHospitality.data);

        const responseRegulaminy = await api.get(`/pages/o/get/regulaminy`);
        setApiDataRegulaminy(responseRegulaminy.data);
      } catch (err) {
        setError(err.message);
        setDataPricing(null);
        setApiDataInformator(null);
        setApiDataPunkty(null);
        setApiDataStadion(null);
        setApiDataHospitality(null);
        setApiDataRegulaminy(null);
      } finally {
        setLoading(false);
      }
    };
    getData();
  }, []);

  return (
    <>
      <Tab.Container defaultActiveKey="cennik">
        <Container className="text-center news-page-tabs-section mt-5 passes-tab-container">
          <Row>
            <Col>
              <Nav className="justify-content-center">
                <Nav.Item className="mx-2">
                  <Nav.Link eventKey="cennik">
                    <img src={pricingImg} className="me-3" />
                    Cennik
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className="mx-2">
                  <Nav.Link eventKey="informator">
                    <img src={informatorImg} className="me-3" />
                    Informator kibica
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className="mx-2">
                  <Nav.Link eventKey="punkty-sprzedazy">
                    <img src={pointsImg} className="me-3" />
                    Punkty sprzedaży
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className="mx-2">
                  <Nav.Link eventKey="stadion">
                    <img src={stadiumImg} className="me-3" />
                    Stadion
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className="mx-2">
                  <Nav.Link eventKey="hospitality">
                    <img src={hospitalityImg} className="me-3" />
                    Catering
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className="mx-2">
                  <Nav.Link eventKey="regulaminy">
                    <img src={termsImg} className="me-3" />
                    Regulaminy
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </Col>
          </Row>
        </Container>
        <Container className="ticketspasses-tab-content-container mb-5">
          <Tab.Content>
            <Tab.Pane eventKey="cennik">
              <Container className="animated fadeInUp">
                <Row className="mb-4 justify-content-center">
                  <h3 className="text-uppercase fw-bold text-center">
                    CENNIKI KARNETÓW
                  </h3>
                </Row>
                <Row>
                  {apiDataPricing &&
                    apiDataPricing.map((v, i) => (
                      <Col
                        sm={12}
                        md={12}
                        lg={6}
                        key={i}
                        className="col-12 mt-4"
                      >
                        <h4>{v.name}</h4>
                        <Table
                          borderless
                          className="pricing-table fw-bold text-center"
                        >
                          <thead className="">
                            <tr>
                              <th className="text-start ps-3">Sektor</th>
                              <th>Normalny</th>
                              <th>Ulgowy</th>
                              <th>Dziecięcy</th>
                            </tr>
                          </thead>
                          <tbody>
                            {v.pricing.map(y => (
                              <>
                                <tr>
                                  <td className="text-start ps-3">
                                    {y.sector}
                                  </td>
                                  <td>
                                    {y.normalPrice.replaceAll(".", ",")} zł
                                  </td>
                                  <td>
                                    {y.reducedPrice.replaceAll(".", ",")} zł
                                  </td>
                                  <td>
                                    {y.childrenPrice.replaceAll(".", ",")} zł
                                  </td>
                                </tr>
                              </>
                            ))}
                          </tbody>
                        </Table>
                      </Col>
                    ))}
                </Row>
              </Container>
              <Container className="mt-4 mb-5 pricing-agenda text-center">
                <Row>
                  <Col>
                    <p className="fw-bold">Normalny</p>
                    Przysługuje osobom pomiedzy 18., a 60. rokiem życia
                  </Col>
                  <Col>
                    <p className="fw-bold">Ulgowy</p>
                    Przysługuje osobom uczącym się pomiędzy 14., a 26. rokiem
                    życia oraz osobom, które ukończyły 60. lat
                  </Col>
                  <Col>
                    <p className="fw-bold">Dziecięcy</p>
                    Przysługuje osobom przed ukończeniem 14. roku
                    życia
                  </Col>
                  <Col>
                    <p className="fw-bold">KDR</p>
                    Posiadaczom Karty Dużej Rodziny przysługuje bilet ulgowy
                  </Col>
                  <Col>
                    <p className="fw-bold">Os. Niepełnosprawna</p>
                    Przysługuje osobom z orzeczeniem o niepełnosprawności
                  </Col>
                </Row>
              </Container>
            </Tab.Pane>
            <Tab.Pane eventKey="informator">
              <TabApiContent data={apiDataInformator} />
            </Tab.Pane>
            <Tab.Pane eventKey="punkty-sprzedazy">
              <TabApiContent data={apiDataPunkty} />
            </Tab.Pane>
            <Tab.Pane eventKey="stadion">
              <TabApiContent data={apiDataStadion} />
            </Tab.Pane>
            <Tab.Pane eventKey="hospitality">
              <TabApiContent data={apiDataHospitality} />
            </Tab.Pane>
            <Tab.Pane eventKey="regulaminy">
              <TabApiContent data={apiDataRegulaminy} />
            </Tab.Pane>
          </Tab.Content>
        </Container>
      </Tab.Container>
    </>
  );
};

export default TabArticles;
