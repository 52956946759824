import React from "react";
import { Col, Container, Row } from "react-bootstrap";

const CompetitionsTable = ({ data }) => {
  return (
    <section className="commonSection pt-5">
      <Container className="container-single-post">
        <Row>
          <Col>
            <div
              className="single-news-content"
              dangerouslySetInnerHTML={{ __html: data && data.content }}
            ></div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default CompetitionsTable;
